import React, { useContext } from "react";
import { Flex, Box, Container, Text, Img, Link } from "@chakra-ui/react";
import { languageContext } from "../../context/LanguageContext";

//@ts-ignore
import freshheads from "./freshheads.png";
//@ts-ignore
import ictrecht from "./ictrecht.png";
//@ts-ignore
import footerImage from "./footerimage.svg";

const footerText = {
  en: {
    text: "Powered by",
  },
  nl: {
    text: "Mede mogelijk gemaakt door",
  },
};

const Footer: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Box mt={["16", "16", "32"]} bg="darkblue">
      <Container py="8" maxW="container.xl" position="relative">
        <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
          <Box flexShrink={0}>
            <Text color="white">{footerText[language].text}</Text>
          </Box>
          <Flex alignItems="center" m="4" flexWrap="wrap">
            <Box m="2">
              <Link
                href="https://freshheads.com"
                isExternal
                _active={{ boxShadow: "none" }}
                _focus={{ boxShadow: "none" }}
              >
                <Img src={freshheads} width="100%" />
              </Link>
            </Box>
            <Box m="2">
              <Link
                href={
                  language === "nl"
                    ? "https://ictrecht.nl"
                    : "https://www.ictrecht.nl/en"
                }
                isExternal
                _active={{ boxShadow: "none" }}
                _focus={{ boxShadow: "none" }}
              >
                <Img src={ictrecht} width="100%" />
              </Link>
            </Box>
            <Box m="2">
              <Link
                color="liladark"
                fontWeight="bold"
                href={
                  language === "nl"
                    ? "https://martijnarets.com/"
                    : "https://www.martijnarets.com/?lang=en"
                }
                isExternal
              >
                Martijn Arets
              </Link>
            </Box>
          </Flex>
        </Flex>
        <Box
          position="absolute"
          bottom="calc(100% - 5.5vw)"
          pointerEvents="none"
          right="16px"
          height="20vw"
        >
          <Img src={footerImage} width="auto" height="100%" alt="shape" />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
