import React, { useContext, useEffect, useState } from "react";
import Nav from "../nav/Nav";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { languageContext } from "../../context/LanguageContext";

type Props = {
  title: {
    nl: string;
    en: string;
  };
};

const Layout: React.FC<Props> = ({ children, title }) => {
  const language = useContext(languageContext);
  const [isCLient, setIsClient] = useState("false"); // this is a temporary fix for language switching

  useEffect(() => {
    setIsClient("true");
  }, [setIsClient]);
  return (
    <div key={isCLient}>
      <Helmet title={language === "nl" ? title.nl : title.en} />
      <main style={{ overflow: "hidden" }}>
        <Nav />
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
