import React, { useContext } from "react";
import { Flex, Box, Button, Container, Img, Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import { languageContext } from "../../context/LanguageContext";
//@ts-ignore
import logo from "../../images/klus-cv-logo.svg";
//@ts-ignore
import logoEnglish from "../../images/gigcv-logo.svg";

const Nav: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Container maxW="container.xl">
      <Flex
        justifyContent="space-between"
        padding="2"
        alignItems="center"
        borderRadius="md"
      >
        <Box>
          <Link to="/" as={GatsbyLink}>
            <Img
              src={language === "nl" ? logo : logoEnglish}
              maxHeight="50px"
              alt="logo"
            />
          </Link>
        </Box>
        <Flex alignItems="center">
          <Box bg={language === "nl" ? "turqoise.50" : ""} m="2">
            <a href="https://kluscv.nl" rel="noopener noreferrer">
              <Button colorScheme="turqoise" variant={"ghost"}>
                NL
              </Button>
            </a>
          </Box>
          |
          <Box
            bg={language === "en" ? "turqoise.50" : ""}
            m="2"
            borderRadius="md"
          >
            <a href="https://gigcv.org" rel="noopener noreferrer">
              <Button colorScheme="turqoise" variant={"ghost"}>
                EN
              </Button>
            </a>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Nav;
